export const APP_CONSTANTS = {
  get APP_NAME() {
    return 'touiq-admin';
  },
  get BASE_URL() {
    return `/${this.APP_NAME}`;
  },
  get BASE_API_URL() {
    return '/api/v2';
  },
  get API_LINKS() {
    return {
      APP_SERVER_SESSION_API: `/admin/myserversession`,
      AUTH: `/users/authenticate`,
      DASHBOARD: '/admin/dashboard',
      RESERVATIONS: '/admin/reservations',
      VALET_HUB: '/admin/valet/hub',
      VEHICLE: '/vehicles/search',
      VALET_RESERVATIONS: '/admin/valet/reservations',
      LOGOUT: '/users/logout',
      PASSWORD: '/users/password',
      USER_PROFILE: '/userandprofile',
    };
  },
  get BASE_ROUTE_URLS() {
    return {
      HOME: `${this.BASE_URL}/home`,
      THEMES: `${this.BASE_URL}/themes`,
      LOGIN: `${this.BASE_URL}/login`,
      REGISTER: `${this.BASE_URL}/register`,
    };
  },
  get APP_ROUTE_URLS() {
    return {
      DASHBOARD: `${this.BASE_URL}/dashboard`,
      RESERVATIONS: `${this.BASE_URL}/reservations`,
      RESERVATION: `${this.BASE_URL}/reservation`,
      CARS: `${this.BASE_URL}/cars`,
      CUSTOMER: `${this.BASE_URL}/customer`,
      HUBS: `${this.BASE_URL}/hubs`,
      CHANGE_PASSWORD: `${this.BASE_URL}/change-password`,
      VALETS: `${this.BASE_URL}/valets`,
    };
  },
  get WHITELISTED_PERSIST_STORE() {
    return ['MetaData', 'Session', 'Token', 'User'];
  },
  get WHITELISTED_COMPRESS_STORE() {
    return [];
  },
  get EXPIRE_OPTION_STORE() {
    return [];
  },
  get EXPIRE_STORE_AFTER_SECOND() {
    return 86400; // 1800 for 30 min // 86400 for 1 day // 21600 6hrs
  },
  get DATE_WITH_TIME_FORMAT() {
    return {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  },
  get DATE_FORMAT() {
    return {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    };
  },
};
