import { LOG_OUT } from '../Logout/constants';
import { DASHBOARD_API, DASHBOARD_INITIAL_STATE } from './constants';

export function Reducer(state = DASHBOARD_INITIAL_STATE, action) {
  switch (action.type) {
    case DASHBOARD_API.REQUEST:
      return { ...state, isFetching: true };
    case DASHBOARD_API.SUCCESS:
      return {
        ...state,
        isFetching: false,
        hubCount: action.data?.hub_count,
        reservationCount: action.data?.reservation_count,
        valetCount: action.data?.valet_count,
        vehicleCount: action.data?.vehicle_count,
        customerCount: action.data?.customer_count,
      };
    case DASHBOARD_API.FAILURE:
      return { ...state, isFetching: false };
    case LOG_OUT:
      return DASHBOARD_INITIAL_STATE;
    default:
      return state;
  }
}
