import { UtilCreateAPIActions } from '../../utils';

export const DASHBOARD_INITIAL_STATE = {
  isFetching: false,
  hubCount: 0,
  reservationCount: 0,
  valetCount: 0,
  vehicleCount: 0,
  customerCount: 0,
};
export const DASHBOARD_API = UtilCreateAPIActions('get', 'dashboard/api');
