import {
  ENQUEUE_NOTIFIER,
  REMOVE_NOTIFIER,
  NOTIFIER_AUTO_HIDE,
  CLOSE_NOTIFIER,
} from './constant';
import { UtilGenerateUniqueID } from '../../utils';

const httpStatusCodeMapToVariant = (statusCode) => {
  if (statusCode) {
    if (statusCode >= 200 && statusCode < 300) {
      return 'success';
    }
    if (statusCode >= 300 && statusCode < 400) {
      return 'info';
    }
    if (statusCode >= 400 && statusCode < 500) {
      return 'error';
    }
    if (statusCode >= 500) {
      return 'warning';
    }
  }
  return 'success';
};

export const enqueueNotifier = (notification) => ({
  type: ENQUEUE_NOTIFIER,
  notification: {
    key: UtilGenerateUniqueID('notifier_'),
    options: {
      autoHideDuration: NOTIFIER_AUTO_HIDE,
      variant: httpStatusCodeMapToVariant(notification.statusCode),
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      preventDuplicate: true,
    },
    ...notification,
  },
});

export const removeNotifier = (key) => ({
  type: REMOVE_NOTIFIER,
  key,
});

export const closeNotifier = (key) => ({
  type: CLOSE_NOTIFIER,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});
