import { CUSTOMER_INITIAL_STATE, CUSTOMER_LIST_API } from './constants';
import { UtilNativeDateFormatter } from '../../utils';
import { APP_CONSTANTS } from '../../constants';

function transformResponse(customerList) {
  return (
    customerList &&
    customerList.length > 0 &&
    customerList.map((customer) => ({
      ...customer,
      name: `${customer?.first_name || ''} ${customer?.last_name || ''}`,
      driving_licence_expiry_date: UtilNativeDateFormatter(
        customer?.driving_licence_expiry_date,
        APP_CONSTANTS.DATE_FORMAT,
      ),
    }))
  );
}

export function Reducer(state = CUSTOMER_INITIAL_STATE, action) {
  switch (action.type) {
    case CUSTOMER_LIST_API.REQUEST:
      return { ...state, isFetching: true };
    case CUSTOMER_LIST_API.SUCCESS:
      return {
        ...state,
        customerList: transformResponse(action.data),
        isFetching: false,
      };
    case CUSTOMER_LIST_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
