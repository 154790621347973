import { GET_METADATA_API, METADATA_INITIAL_STATE } from './constants';
import { LOG_OUT } from '../Logout/constants';

export function Reducer(state = METADATA_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_METADATA_API.REQUEST:
      return { ...state, isFetching: true };
    case GET_METADATA_API.SUCCESS:
      return {
        ...state,
      };
    case GET_METADATA_API.FAILURE:
      return { ...state, isFetching: false };
    case LOG_OUT:
      return METADATA_INITIAL_STATE;
    default:
      return state;
  }
}
