import React from 'react';
import loadable from '@loadable/component';
import Fade from '@material-ui/core/Fade';
import { Redirect, Route, Switch } from 'react-router';
import { APP_CONSTANTS } from './constants';

const LoginPage = loadable(() =>
  import(/* webpackPrefetch: true */ './pages/Login'),
);
const AppRouteResolver = loadable(() =>
  import(/* webpackPrefetch: true */ './packages/AppRouteResolver'),
);

export default function BaseRoutes() {
  return (
    <Fade>
      <Switch>
        <Route
          path={APP_CONSTANTS.BASE_ROUTE_URLS.LOGIN}
          render={() => <LoginPage />}
        />
        <Route
          path={APP_CONSTANTS.BASE_URL}
          render={() => <AppRouteResolver />}
        />
        <Route
          path="/"
          render={() => <Redirect to={APP_CONSTANTS.BASE_URL} />}
        />
      </Switch>
    </Fade>
  );
}
