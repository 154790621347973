import { GET_VEHICLES_LIST_API, VEHICLE_INITIAL_STATE } from './constants';

export function Reducer(state = VEHICLE_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_VEHICLES_LIST_API.REQUEST:
      return { ...state, isFetching: true };
    case GET_VEHICLES_LIST_API.SUCCESS:
      return { ...state, isFetching: false };
    case GET_VEHICLES_LIST_API.FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
