import { UtilCreateAPIActions } from '../../utils';

export const CUSTOMER_INITIAL_STATE = {
  customerList: [],
  isFetching: false,
};
export const CUSTOMER_LIST_API = UtilCreateAPIActions(
  'get',
  'customer/list/api',
);
export const CUSTOMER_DETAILS_API = UtilCreateAPIActions(
  'get',
  'customer/details/api',
);
export const UPDATE_CUSTOMER_DETAILS_API = UtilCreateAPIActions(
  'update',
  'customer/details/api',
);
