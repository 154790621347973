import { LOG_OUT } from '../Logout/constants';
import { GET_RESERVATION_API, RESERVATION_INITIAL_STATE } from './constants';
import { UtilDateParser } from '../../utils';

function transformReservationResponse(reservations) {
  return (
    reservations &&
    reservations.length > 0 &&
    reservations.map((reservation) => ({
      ...reservation,
      customer_name: `${reservation?.customer_info?.first_name || ''} ${
        reservation?.customer_info?.last_name || ''
      }`,
      pickup_time: UtilDateParser(reservation?.reservation_info?.pickup_time),
      drop_off_time: UtilDateParser(
        reservation?.reservation_info?.dropoff_time,
      ),
    }))
  );
}

export function Reducer(state = RESERVATION_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_RESERVATION_API.REQUEST:
      return { ...state, isFetching: true };
    case GET_RESERVATION_API.SUCCESS:
      return {
        ...state,
        isFetching: false,
        reservationList: transformReservationResponse(action.data),
      };
    case GET_RESERVATION_API.FAILURE:
      return { ...state, isFetching: false };
    case LOG_OUT:
      return RESERVATION_INITIAL_STATE;
    default:
      return state;
  }
}
