import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { Reducer as Login } from './Login/reducer';
import { Reducer as MetaData } from './MetaData/reducer';
import { Reducer as Session } from './Session/reducer';
import { Reducer as Theme } from './Theme/reducer';
import { Reducer as Token } from './Token/reducer';
import { Reducer as User } from './User/reducer';
import { Reducer as Dashboard } from './Dashboard/reducer';
import { Reducer as Reservation } from './Reservation/reducer';
import { Reducer as Customer } from './Customer/reducer';
import { Reducer as Notifier } from '../packages/AppNotifier/reducer';
import { Reducer as FetchApiList } from '../api/reducer';
import { Reducer as Vehicle } from './Vehicle/reducer';

export default function RootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    Login,
    MetaData,
    Session,
    Theme,
    Token,
    User,
    FetchApiList,
    Notifier,
    Dashboard,
    Reservation,
    Customer,
    Vehicle,
  });
}
