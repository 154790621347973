import { GET_APP_SERVER_SESSION_API, SESSION_INITIAL_STATE } from './constants';
import { SET_TOKEN } from '../Token/constants';
import { LOG_OUT } from '../Logout/constants';

export function Reducer(state = SESSION_INITIAL_STATE, action) {
  switch (action.type) {
    case GET_APP_SERVER_SESSION_API.REQUEST:
      return { ...state, isFetching: true };
    case SET_TOKEN:
    case GET_APP_SERVER_SESSION_API.SUCCESS:
      return {
        ...state,
        isAuthenticated: action?.data?.authenticated || action.loggedIn,
        googleKey: action?.data?.gak,
        isFetching: false,
      };
    case GET_APP_SERVER_SESSION_API.FAILURE:
      return { ...state, isFetching: false };
    case LOG_OUT:
      return SESSION_INITIAL_STATE;
    default:
      return state;
  }
}
