import { SET_THEME, THEME_INITIAL_STATE } from './constants';

export function Reducer(state = THEME_INITIAL_STATE, action) {
  switch (action.type) {
    case SET_THEME: {
      return {
        ...state,
        primary: action.primaryColor,
        secondary: action.secondaryColor,
      };
    }
    default:
      return state;
  }
}
