import { UtilCreateAPIActions } from '../../utils';

export const RESERVATION_INITIAL_STATE = {
  isFetching: false,
  reservationList: [],
};
export const GET_RESERVATION_API = UtilCreateAPIActions(
  'get',
  'reservation/api',
);
export const GET_RESERVATION_BY_ID_API = UtilCreateAPIActions(
  'get',
  'reservation/id/api',
);
export const UPDATE_RESERVATION_BY_ID_API = UtilCreateAPIActions(
  'update',
  'reservation/id/api',
);
export const CLOSE_RESERVATION_BY_ID_API = UtilCreateAPIActions(
  'close',
  'reservation/id/api',
);
