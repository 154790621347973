import { UtilCreateAPIActions } from '../../utils';

export const USER_INITIAL_STATE = {
  userType: '',
  firstName: '',
  lastName: '',
  middleName: '',
};
export const SET_USER_INFO = 'SET_USER_INFO';
export const UPDATE_USER_PASSWORD_API = UtilCreateAPIActions(
  'update',
  'user/password/api',
);
