import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const StyledLoaderContainer = styled.div`
  position: ${(props) => (props.fullPage ? 'fixed' : 'relative')};
  width: ${(props) => (props.fullPage ? '100vw' : '100%')};
  height: ${(props) => (props.fullPage ? '100vh' : '100%')};
  top: 0;
  left: 0;
  z-index: 2001;
  .circular-loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${(props) => props.position};
    top: 0;
    left: 0;
    .progress-with-text {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
  .linear-loader {
    width: 100%;
    height: 100%;
    position: ${(props) => props.position};
    top: 0;
    left: 0;
  }
`;

export default function AppProgressIndicator(props) {
  const theme = useTheme();
  const { progressType, fullPage, position, ...other } = props;
  return (
    <StyledLoaderContainer fullPage={fullPage} position={position}>
      {progressType === 'linear' ? (
        <div className="linear-loader">
          <LinearProgress {...other} />
        </div>
      ) : (
        <div className="circular-loader">
          <div className="progress-with-text">
            <CircularProgress {...other} />
            <Typography
              variant="subtitle1"
              align="center"
              color="inherit"
              component="p"
              style={{
                color: theme.palette.common.white,
              }}
            >
              Please wait...
            </Typography>
            {/* <Typography
              variant="subtitle1"
              align="center"
              color="inherit"
              component="p"
              style={{
                color: theme.palette.common.white,
              }}
            >
              This may take up to a minute
            </Typography> */}
          </div>
        </div>
      )}
    </StyledLoaderContainer>
  );
}

AppProgressIndicator.propTypes = {
  progressType: PropTypes.oneOf(['circular', 'linear']),
  fullPage: PropTypes.bool,
  position: PropTypes.oneOf(['fixed', 'absolute', 'relative', 'static']),
};
AppProgressIndicator.defaultProps = {
  progressType: 'circular',
  fullPage: false,
  position: 'static',
};
