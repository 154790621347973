import * as _ from 'lodash';
import { APP_CONSTANTS } from '../constants';

export const UtilToLower = (str) => (str ? str.toLowerCase() : '');

export const UtilIsString = (str) => (str ? str.isString() : false);

export const UtilIsBool = (val) => (val ? val.isBoolean() : false);

export const UtilGetValueUsingKey = (obj, key) =>
  obj && obj[key] ? obj[key] : false;

export const UtilGetUniqueKey = (name, index) =>
  UtilToLower(`uid_${name}_${index}`);

export const UtilCreateAPIActions = (prefix, suffix) => {
  const PREFIX = prefix.toUpperCase();
  const SUFFIX = suffix.toUpperCase();
  return {
    NAME: `${PREFIX}/${SUFFIX}`,
    REQUEST: `${PREFIX}/${SUFFIX}_REQUEST`,
    SUCCESS: `${PREFIX}/${SUFFIX}_SUCCESS`,
    FAILURE: `${PREFIX}/${SUFFIX}_FAILURE`,
  };
};

export function UtilDebounced(delay, fn) {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export const UtilGetFirstCharFromString = (str) => {
  if (str && UtilIsString(str) && str.length > 0) {
    return str.charAt(0);
  }
  return '';
};

export function UtilConvertTimeToAMPM(convertTime) {
  let time = convertTime;
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

export const UtilGenerateUniqueID = (prefix, suffix) =>
  UtilToLower(`${prefix || 'uid'}_${Date.now()}_${suffix || ''}`);

export const UtilValidateForm = (requiredFields, fieldError) => {
  let validFieldCount = 0;
  if (requiredFields && Array.isArray(requiredFields)) {
    requiredFields.forEach((key) => {
      if (fieldError[key] !== undefined && fieldError[key] === false) {
        validFieldCount += 1;
      }
    });
  }
  return validFieldCount === requiredFields.length;
};

export const UtilDateFormatter = {
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  dayNames: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  formatDate(date, format) {
    const self = this;
    let formatValue = format;
    formatValue = self.getProperDigits(formatValue, /d+/gi, date.getDate());
    formatValue = self.getProperDigits(formatValue, /M+/g, date.getMonth() + 1);
    formatValue = formatValue.replace(/y+/gi, (y) => {
      const len = y.length;
      const year = date.getFullYear();
      if (len === 2) return `${year}`.slice(-2);
      if (len === 4) return year;
      return y;
    });
    formatValue = self.getProperDigits(formatValue, /H+/g, date.getHours());
    formatValue = self.getProperDigits(
      formatValue,
      /h+/g,
      self.getHours12(date.getHours()),
    );
    formatValue = self.getProperDigits(formatValue, /m+/g, date.getMinutes());
    formatValue = self.getProperDigits(formatValue, /s+/gi, date.getSeconds());
    formatValue = formatValue.replace(/a/gi, (a) => {
      const amPm = self.getAmPm(date.getHours());
      if (a === 'A') return amPm.toUpperCase();
      return amPm;
    });
    formatValue = self.getFullOr3Letters(
      formatValue,
      /d+/gi,
      self.dayNames,
      date.getDay(),
    );
    formatValue = self.getFullOr3Letters(
      formatValue,
      /M+/g,
      self.monthNames,
      date.getMonth(),
    );
    return formatValue;
  },
  getProperDigits(format, regex, value) {
    return format.replace(regex, (m) => {
      const { length } = m;
      if (length === 1) return value;
      if (length === 2) return `0${value}`.slice(-2);
      return m;
    });
  },
  getHours12(hours) {
    return (hours + 24) % 12 || 12;
  },
  getAmPm(hours) {
    return hours >= 12 ? 'pm' : 'am';
  },
  getFullOr3Letters(format, regex, nameArray, value) {
    return format.replace(regex, (s) => {
      const len = s.length;
      if (len === 3) return nameArray[value].substr(0, 3);
      if (len === 4) return nameArray[value];
      return s;
    });
  },
};

export function UtilGenerateAddressFromGoogleResponse(response) {
  let address_line = '';
  let city = '';
  let state = '';
  let postal_code = '';
  let country_code = '';
  _.forEach(
    _.get(response, 'data.results[0].address_components'),
    (eachAddress) => {
      if (_.includes(eachAddress?.types, 'street_number')) {
        address_line = `${address_line} ${eachAddress?.long_name}`.trim();
      } else if (_.includes(eachAddress?.types, 'route')) {
        address_line = `${address_line} ${eachAddress?.long_name}`.trim();
      } else if (_.includes(eachAddress?.types, 'locality')) {
        city = eachAddress?.long_name;
      } else if (
        _.includes(eachAddress?.types, 'administrative_area_level_1')
      ) {
        state = eachAddress?.long_name;
      } else if (_.includes(eachAddress?.types, 'postal_code')) {
        postal_code = eachAddress?.long_name;
      } else if (_.includes(eachAddress?.types, 'country')) {
        country_code = eachAddress?.long_name;
      }
    },
  );
  return {
    // place_id: _.get(response, 'data.results[0].place_id'),
    address_line,
    city,
    state,
    postal_code,
    country_code: country_code || 'US',
    latitude: _.get(response, 'data.results[0].geometry.location.lat'),
    longitude: _.get(response, 'data.results[0].geometry.location.lng'),
  };
}
export function UtilFilterDataWithKeys(inputValue, dataList, keysToFilter) {
  let isIncludes = false;
  _.forEach(keysToFilter, (resKey) => {
    if (_.includes(_.toLower(_.get(dataList, resKey)), _.toLower(inputValue))) {
      isIncludes = true;
    }
  });

  return isIncludes;
}
export function UtilNativeDateFormatter(date, format) {
  return date && new Date(date).toString() !== 'Invalid Date'
    ? new Date(date).toLocaleString(
        'en-US',
        format || APP_CONSTANTS.DATE_WITH_TIME_FORMAT,
      )
    : date;
}

// Date Format in Response "2020-08-20T14:30:00"
// Convert above format to MM/DD/YYYY hh:mm A
export function UtilDateParser(date) {
  if (date) {
    const formattedDateAndTime = date.split('T');
    const formattedDate = formattedDateAndTime[0].split('-');
    const formattedTime = formattedDateAndTime[1]
      ? UtilConvertTimeToAMPM(formattedDateAndTime[1].slice(0, -3))
      : '';
    return `${formattedDate[1]}/${formattedDate[2]}/${formattedDate[0]} ${formattedTime}`;
  }
  return null;
}

export function UtilCleanObjectAttributes(obj) {
  const modifiedObj = obj;
  Object.keys(modifiedObj).forEach(
    (key) =>
      (modifiedObj[key] === null ||
        modifiedObj[key] === undefined ||
        modifiedObj[key] === '') &&
      delete modifiedObj[key],
  );
  return modifiedObj;
}
