import { UtilCreateAPIActions } from '../../utils';

export const SESSION_INITIAL_STATE = {
  isFetching: false,
  isAuthenticated: false,
};

export const GET_APP_SERVER_SESSION_API = UtilCreateAPIActions(
  'get',
  'app_server_session/api',
);
