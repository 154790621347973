import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as serviceWorker from './utils/serviceWorker';
import historyObject from './utils/History';
import { configureStore } from './store';
import AppProgressIndicator from './packages/AppProgressIndicator';
import WithThemes from './packages/AppTheme';
import BaseRoutes from './routes';
import Notifier from './packages/AppNotifier';

import './assets/fonts/index.css';
import 'animate.css';

const { store, memStore } = configureStore(historyObject);
const MOUNT_NODE = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <ConnectedRouter history={historyObject}>
          <PersistGate loading={<AppProgressIndicator />} persistor={memStore}>
            <WithThemes>
              <Notifier />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <BaseRoutes />
              </MuiPickersUtilsProvider>
            </WithThemes>
          </PersistGate>
        </ConnectedRouter>
      </Provider>
    </AppContainer>,
    MOUNT_NODE,
  );
};

if (module?.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app/index.js'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
