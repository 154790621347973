import { CLOSE_NOTIFIER, ENQUEUE_NOTIFIER, REMOVE_NOTIFIER } from './constant';

const defaultState = {
  notifications: [],
  loadedAt: new Date().toJSON(),
};

const filterDuplicateMsg = (state, action) => {
  let { notifications = [] } = state;
  const notificationIndex =
    notifications &&
    notifications.length > 0 &&
    notifications.findIndex((o) => o.message === action.notification.message);

  if (!notificationIndex || notificationIndex < 0) {
    notifications = [
      ...state.notifications,
      {
        key: action.key,
        ...action.notification,
      },
    ];
  }

  return notifications;
};

export function Reducer(state = defaultState, action) {
  switch (action.type) {
    case ENQUEUE_NOTIFIER:
      return {
        ...state,
        notifications: filterDuplicateMsg(state, action),
      };

    case CLOSE_NOTIFIER:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification },
        ),
      };

    case REMOVE_NOTIFIER:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key,
        ),
      };

    default:
      return state;
  }
}
